import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ImageRow } from "../projects/roboto"
import autumn from "../images/autumnleaves.png"
import theme from "../images/themevariations.png"
import quartet from "../images/stringquartet.png"
import emerson1 from "../images/emerson1.png"
import emerson2 from "../images/emerson2.png"

const Music = () => (
  <Layout>
    <SEO title="Music" />
    <div style={{ padding: "0 2rem" }}>
      <h1>
        Music Major Research Capstone: The Evolution of Piano Experimentation
      </h1>
      <p>
        Throughout fall 2019, I investigated the intersection of music and
        mechanical engineering, which culminated in a musicology paper and talk.
        I examined books on the history of piano design and manufacturing, piano
        technician handbooks, and scientific papers on mechanical studies of the
        piano. I also interviewed piano technicians/tuners from MIT and faculty
        from North Bennett Street School, a renowned trade school in Boston.
        "Piano experimentation" is essentially treating the piano as a
        mechanical system and approaching it from an experimental lens.
        <br />
        <br /> Abstract: For over two centuries since its invention in the early
        1700s, the piano’s design had been continuously evolving in comparison
        to the design of most other instruments due to its complex mechanisms
        involving the hammer-key action, strings, soundboard and iron frame. Due
        to business and monetary pressures and demands of pianists, piano makers
        constantly experimented with parameters of the piano’s design to attempt
        to achieve a better instrument with optimal tone and quality of sound.
        For example, Johann Stein experimented and modified Schröter’s design of
        piano action and invented keys with more elastic touch and soulful tone
        and Siegfried Hansing conducted experiments to construct an improved
        soundboard strengthened by bars. Such experimentation was often
        motivated by the prospect of invention and judged by qualitative
        evidence such as tone quality and sound length. In the past century, as
        piano design changes waned and the need for piano maintenance increased
        due to widespread ownership of the instruments, the prominence of piano
        technicians and rebuilders as experimenters rose. Several piano
        handbooks from the 1900s describe experimental processes completed by
        technicians, such as regulating average key height, rebuilding piano
        action, and changing the touch weight of keys through measurement of
        each individual key. These processes became relatively uniform in
        practice, emphasizing precision rather than uniqueness. In contrast,
        scientific papers from the past decade detail experimental setups used
        to describe technical physics aspects of piano mechanisms, such as the
        measurement of hammer acceleration and soundboard properties, presenting
        results with numerical quantities and complex, abstract equations in a
        passive, observational manner. Ultimately, this work will first examine
        historical details of experimentation of pianos, then analyze and
        compare piano handbooks and scientific papers, and finally question the
        relevance of current experimental methods using personal accounts of
        piano builders in the Boston area. The work aims to compare the
        motivations and outcomes of experimentation on pianos, showing a shift
        from qualitative to quantitative results and a more passive outlook on
        experimentation today as it has less impact on piano design.
      </p>
      <h1>Composition</h1>
      <ImageRow
        images={[
          { image: autumn, caption: "Jazz arrangement" },
          { image: theme, caption: "Theme & Variations" },
          { image: quartet, caption: "String quartet" },
        ]}
        height="25"
      />
      <h1>Performance</h1>
      <p>
        In 2018, I had a 50-minute solo piano recital as an MIT Emerson Scholar.
        My program notes are below:
      </p>
      <ImageRow images={[emerson1, emerson2]} height="25" />
      <p>
        For several years, I performed in a piano trio with Margaret Zhang,
        cello, and Cathy Choi, violin. Past repertoire includes: Arensky Piano
        Trio No. 1, Rachmaninoff Trio Elegiaque No. 1, Brahms Piano Trio No. 2.
        Recordings to come! <br /> <br />A previous performance for music school
        auditions:
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <iframe
          width="560"
          height="315"
          style={{ borderRadius: "5px" }}
          src="https://www.youtube.com/embed/QjeSyIrJxNs"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </Layout>
)

export default Music
